import Head from 'next/head';
import type { ReactElement } from 'react';

import { brandConfig } from '@hubcms/brand';
import {
  isArticleContext,
  isArticleOrExtraPageContext,
  isContextWithElements,
  type StoryElementTextField,
  type ContextQueryResultData,
  isElementWithRelation,
  isElementPicture,
  isTagPage,
  type CookData,
  isAudioVisualArticleContext,
} from '@hubcms/domain-cook';
import { getPageHref } from '@hubcms/feature-cook';
import { getSizedUrl } from '@hubcms/utils-images';
import { getElementField } from '@hubcms/utils-story-elements';

function OpenGraphTags({ data }: { data: CookData }): ReactElement {
  const { context } = data;

  const image = getImage(context);
  const intro = getIntro(context);
  const title = getArticleHeadline(context);
  const href = getPageHref({ data });

  const [metadataMime] = (image?.metadata ?? []).filter(({ key }) => key === 'mime-type');
  const metadataHeight = image?.height;
  const metadataWidth = image?.width;

  const ogLocale = brandConfig.locale.replace('-', '_');
  const hasIntro = Boolean(intro?.length);
  return (
    <Head>
      <meta property="og:locale" content={ogLocale} />
      <meta property="og:site_name" content={data.sectionParams['boilerplate.brandname']} />
      <meta property="og:type" content="article" />
      {title && <meta property="og:title" content={title} />}
      {hasIntro && <meta property="og:description" content={intro as string} />}
      {href && <meta property="og:url" content={href} />}
      {image?.href_full && (
        <>
          <meta property="og:image" content={getSizedUrl(image.href_full, 'sixteen_nine', 1200)} />
          <meta property="og:image:secure_url" content={getSizedUrl(image.href_full, 'sixteen_nine', 1200)} />
          {metadataMime?.value && <meta property="og:image:type" content={metadataMime.value} />}
          <meta property="og:image:width" content={`${metadataWidth}`} />
          <meta property="og:image:height" content={`${metadataHeight}`} />
        </>
      )}
    </Head>
  );
}

function TwitterTags({ data }: { data: CookData }): ReactElement {
  const { context, sectionParams } = data;
  const twitterHandle = sectionParams['socialsharing.twitter.handle']?.replace('@', '');
  const image = getImage(context);
  const intro = getIntro(context);
  const title = getArticleHeadline(data.context);
  const href = getPageHref({ data });
  return (
    <Head>
      <meta name="twitter:card" content="summary_large_image" />
      {twitterHandle && <meta name="twitter:site" content={`@${twitterHandle}`} />}
      {href && <meta name="twitter:url" content={href} />}
      {title && <meta name="twitter:title" content={title} />}
      {intro && <meta name="twitter:description" content={intro} />}
      {image && image.href_full && (
        <>
          <meta name="twitter:image" content={image.href_full} />
          {image.caption && <meta name="twitter:image:alt" content={image.caption} />}
        </>
      )}
    </Head>
  );
}

function getImage(context: ContextQueryResultData['context']) {
  if (isArticleContext(context)) {
    const teaserImage = { caption: '', metadata: [], ...context.teaserImage?.[0]?.content?.fields?.sixteenNine };
    if (teaserImage.href_full) {
      return teaserImage;
    }

    if (!isContextWithElements(context)) {
      return null;
    }
    const [storylineImage] = context.elements?.filter(({ type }) => ['picture', 'hero_media'].includes(type)) ?? [];

    if (isElementWithRelation(storylineImage) && isElementPicture(storylineImage.relation)) {
      const { relation } = storylineImage;
      const relationCaption = (storylineImage.fields ?? []).filter(el => el.name === 'caption').pop() as
        | StoryElementTextField
        | undefined;
      const caption = relationCaption?.value ?? relation.caption ?? '';

      return {
        caption,
        metadata: storylineImage.relation.fields.com_escenic_defaultmetadata, // eslint-disable-line camelcase
        ...storylineImage.relation.fields.sixteenNine,
      };
    }
  }
  return null;
}

const getArticleHeadline = (context: ContextQueryResultData['context']): string => {
  if (isContextWithElements(context)) {
    const elements = context.elements ?? [];
    const teaserHeadline = elements.filter(el => el.type === 'teaser_headline')[0];
    const headline = elements.filter(el => el.type === 'headline')[0];

    return (
      (teaserHeadline && getElementField<string>(teaserHeadline.fields, 'teaserHeadline')) ??
      (headline && getElementField<string>(headline.fields, 'headline')) ??
      context.title
    );
  }
  if (isAudioVisualArticleContext(context)) {
    return context.fields.title ?? '';
  }
  return '';
};

function getIntro(context: ContextQueryResultData['context']) {
  let intro: string | null = null;

  if (isContextWithElements(context)) {
    const [teaserLead] = context.elements.filter(({ type }) => type === 'teaser_intro');
    intro = teaserLead && getElementField<string>(teaserLead.fields, 'teaserIntro');
    if (!intro) {
      const [lead] = context.elements.filter(({ type }) => type === 'intro');
      intro = lead && getElementField<string>(lead.fields, 'intro');
    }
    return intro;
  }
  if (isAudioVisualArticleContext(context)) {
    return context.fields.intro;
  }
  return '';
}

export default function SocialMetaTags({ cookData: data }: { cookData: CookData }): ReactElement | null {
  if (!isTagPage(data) && !isArticleOrExtraPageContext(data.context)) {
    return null;
  }
  return (
    <>
      <OpenGraphTags data={data} />
      <TwitterTags data={data} />
    </>
  );
}
